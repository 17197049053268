import { NgModule } from "@angular/core";
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


@NgModule({
    declarations: [],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        // material designs
        MatButtonModule, 
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatTableModule,
        MatListModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatSidenavModule,
        MatMenuModule,
        MatChipsModule,
        MatDividerModule,
        MatPaginatorModule,
        MatTabsModule,
        MatIconModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        // material designs
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatTableModule,
        MatListModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatSidenavModule,
        MatMenuModule,
        MatChipsModule,
        MatDividerModule,
        MatPaginatorModule,
        MatIconModule,
        MatTabsModule,
        MatDialogModule,
    ]
})
export class SharedModule { }