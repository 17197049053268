import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './core/components/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { LoginHeaderComponent } from './core/components/login-header/login-header.component';

const ngZorroConfig: NzConfig = {
  theme: {
    primaryColor: '#479E73'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginHeaderComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NzButtonModule,
    BrowserAnimationsModule,
    NzMessageModule,
    AngularSvgIconModule.forRoot(),
    BrowserAnimationsModule, // required animations module
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  },
  { 
    provide: NZ_I18N, 
    useValue: { ru_RU, ...{
      Pagination: {
        items_per_page: '',
      }
    } }
  },
  { 
    provide: NZ_CONFIG, useValue:  ngZorroConfig  
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
