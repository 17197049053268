<mat-sidenav-container class="login bg_login" fullscreen>
    <mat-sidenav-content>
        <app-login-header></app-login-header>
        <mat-card class="bg_form">
            <mat-card-content>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <h2 class="text-4 mb-30">Вход</h2>
                    <mat-error *ngIf="loginInvalid">
                        Неправильные логин или пароль
                    </mat-error>
                    <mat-form-field appearance="outline" class="mb-30">
                        <mat-label>Логин</mat-label>
                        <input matInput placeholder="Логин" formControlName="username" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="mb-30">
                        <mat-label>Пароль</mat-label>
                        <input matInput [type]="showPassword ? 'text' : 'password'" placeholder="Пароль" formControlName="password" required>
                        <mat-icon (click)="togglePasswordVisibility($event)" class="pointer" matSuffix>{{showPassword?'visibility_off':'visibility'}}</mat-icon>
                    </mat-form-field>
                    <div class="btn-block">
                        <button nz-button nzType="primary" class="d-flex btn flex-center"><div class="mr-4">Войти</div><svg-icon src="assets/icons/thin_2.svg" [svgStyle]="{ 'margin-top': '6px', fill: 'white' }"></svg-icon></button>
                        <button nz-button nzType="default" class="d-flex btn mt-16 flex-center"><div class="mr-4">Нет аккаунта</div></button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </mat-sidenav-content>
</mat-sidenav-container>