<div class="header">
    <div class="logo-img">
        <img class="logo-pc" routerLink="/" src="assets/svg/logo.svg">
        <img class="logo-m" routerLink="/" src="assets/svg/m_logo.svg">
    </div>
    <div class="info-box">
        <div></div>
        <div class="divider"></div>
        <div class="d-flex flex-center pointer">
            <div class="mr-8">Нет аккаунта</div><svg-icon src="assets/icons/thin_2.svg" [svgStyle]="{ 'margin-top': '6px', fill: '#4A5B86' }"></svg-icon>
        </div>
    </div>
</div>