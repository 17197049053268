import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { authConstants } from 'src/app/shared/constants/auth';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {
  public defaultErrorMessage = 'Ошибка сервера';
  public errorTypesMapping = {
    'Authorization for the token has failed or expired token': 'Авторизация для токена не удалась или срок действия токена истек.'
  };

  constructor(private router: Router) {}

  public getErrorMessage(error: HttpErrorResponse): string {
    const { message } = error;

    if (error.message === 'Authorization for the token has failed or expired token') {
        localStorage.removeItem(authConstants.tokenName);
        localStorage.removeItem(authConstants.tokenExpire);
        this.router.navigateByUrl('/login');
    }

    return this.defaultErrorMessage;
  }
}
