import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedOutGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate() {    
    if (this.auth.isLoggedIn()) {
      this.router.navigateByUrl('/catalog');

      return false;
    }

    return true;
  }
}
