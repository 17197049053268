import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorMessagesService } from '../services/error-messages.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorMessages: ErrorMessagesService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        const serverError = error.error;
        const message = this.errorMessages.getErrorMessage(serverError);

        if (serverError) {
          return throwError({
            status: serverError.message,
            message,
          });
        }

        return throwError(error);
      })
    );
  }
}
