import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, tap } from 'rxjs';
import { authConstants } from '../shared/constants/auth';
import { SignInResponseDto } from '../shared/dto/sign-in-response.dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private identifier!: string;
  private apiPath: string = `${environment.bitrix}/api`;

  constructor(private http: HttpClient) { }

  signIn(login: string, password: string): Observable<SignInResponseDto> {
    return this.http.post<SignInResponseDto>(`${this.apiPath}/login`, { login, password, identifier: this.identifier });
  }

  getToken(name: string): string | null {
    return localStorage.getItem(name);
  }

  saveAuthData(data: SignInResponseDto): void {
    localStorage.setItem(authConstants.tokenName, data.token);
    localStorage.setItem(authConstants.tokenExpire, data.expire);
  }

  isTokenExpired(): boolean {
    const tokenExpire = localStorage.getItem(authConstants.tokenExpire) ?? '';
    const token = localStorage.getItem(authConstants.tokenName);

    if (!token) {
      return true;
    }

    return moment()
      .utc()
      .isAfter(moment(tokenExpire, 'DD.MM.YYYY HH:mm:ss').toDate());
  }

  isLoggedIn(): boolean {
    return !this.isTokenExpired();
  }


  logout(): Observable<unknown> {
    const accessToken = this.getToken(authConstants.tokenName);

    // let httpHeaders = new HttpHeaders();

    // return this.http.post<unknown>(`${this.apiPath}/api/logout`, { login, password, identifier: this.identifier });

    // httpHeaders = httpHeaders.append('Authorization', `Bearer ${accessToken}`);
    // { headers: httpHeaders }
    return this.http.post(`${this.apiPath}/logout`, {}).pipe(tap(() => {
      localStorage.removeItem(authConstants.tokenName);
      localStorage.removeItem(authConstants.tokenExpire);
    }));
  }
}
