import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth.service';
import { authConstants } from '../../shared/constants/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshToken$$ = new BehaviorSubject<string | null>(null);

  private freeRoutes = ['/login/'];

  constructor(private readonly authService: AuthService) {}

  isRouteFree(url: string): boolean {
    return this.freeRoutes.findIndex((route) => url.indexOf(route) !== -1) > -1;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isRouteFree(request.url)) {
      return next.handle(request);
    }

    const accessExpired = this.authService.isTokenExpired();

    if (accessExpired) {
      console.log('33');
      
      // return this.requestRefreshToken(request, next);
    }

    return next.handle(this.injectToken(request));
  }

  injectToken(request: HttpRequest<unknown>) {
    const token = this.authService.getToken(authConstants.tokenName);

    return request.clone({
      setHeaders: {
        'Authorization-Token': `${token}`
      }
    });
  }
}
