import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';

interface LoginForm {
  username: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'opt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {
  public form: FormGroup<LoginForm> = this.formBuilder.group({
    username: [''],
    password: ['']
  });
  public loginInvalid: boolean;
  public showFormErrors = false;
  public showPassword: boolean = false;

  constructor(private authService: AuthService, private formBuilder: UntypedFormBuilder, private router: Router) { }

  async onSubmit() {
    this.loginInvalid = false;

    if (this.form.invalid) {
      this.showFormErrors = true;

      return;
    }

    const username = this.form.controls.username.value;
    const password = this.form.controls.password.value;
    this.authService.signIn(username, password).subscribe({
      next: (data) => {

        this.authService.saveAuthData(data);

        this.router.navigateByUrl('/catalog');
      }, error: () => {
        this.loginInvalid = true;
      }
    });

  }

  public togglePasswordVisibility(e: Event): void {
    this.showPassword = !this.showPassword;

    e.stopPropagation();
  }
}
